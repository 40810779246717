import * as React from 'react';
import { css, keyframes } from '@emotion/core';
import { easeOutExpo, transitionTime } from '../util/easings';
import Down from './down';
import { useScrolled } from '../util/hooks';
import { breaks } from '../util/mq';

const bounce = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    transform: translate3d(0, -30px, 0);
  }

  70% {
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0,-4px,0);
  }
`;

export type Props = React.PropsWithChildren<{
  src: string;
  srcSet: string;
  unsplashUser?: string;
  author?: string;
  darken?: string;
  title?: string;
  subTitle?: string;
  position?: string;
}> &
  Pick<React.HTMLProps<HTMLDivElement>, 'className'>;

const Hero = ({
  src,
  srcSet,
  title,
  subTitle,
  unsplashUser,
  author,
  darken,
  position = 'center',
  children,
  className,
}: Props) => {
  const innerHeight = typeof window !== 'undefined' ? window.innerHeight : 0;
  const scrolled = useScrolled(innerHeight / 4);

  const handleDownClick = React.useCallback(() => {
    window.scrollTo({
      top: window.innerHeight * 0.95,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div
      className={className}
      css={css`
        width: 100vw;
        height: 100vh;
        margin-bottom: 40px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &::after {
          content: '';
          display: block;
          background: ${darken
            ? `rgba(0, 0, 0, ${darken})`
            : 'rgba(255, 0, 0, 0.05)'};
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
        @media print {
          display: none;
        }
      `}
    >
      <a
        css={css`
          background-color: black;
          color: white;
          text-decoration: none;
          padding: 4px 6px;
          font-family: -apple-system, BlinkMacSystemFont, 'San Francisco',
            'Helvetica Neue', Helvetica, Ubuntu, Roboto, Noto, 'Segoe UI', Arial,
            sans-serif;
          font-size: 12px;
          font-weight: bold;
          line-height: 1.2;
          display: inline-block;
          border-radius: 3px;
          position: absolute;
          left: 4px;
          bottom: 4px;
          opacity: 0.2;
          z-index: 2;
          transition: opacity ${transitionTime} ${easeOutExpo};
          &:hover {
            opacity: 1;
          }
        `}
        href={`https://unsplash.com/@${unsplashUser}?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge`}
        target="_blank"
        rel="noopener noreferrer"
        title={`Download free do whatever you want high-resolution photos from ${author}`}
      >
        <span
          css={css`
            display: inline-block;
            padding: 2px 3px;
          `}
        >
          <svg
            css={css`
              height: 12px;
              width: auto;
              position: relative;
              vertical-align: middle;
              top: -2px;
              fill: white;
            `}
            viewBox="0 0 32 32"
          >
            <title>unsplash-logo</title>
            <path d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z"></path>
          </svg>
        </span>
        <span
          css={css`
            display: inline-block;
            padding: 2px 3px;
          `}
        >
          {author}
        </span>
      </a>
      <img
        src={src}
        srcSet={srcSet}
        alt={author ? `Photo By ${author}` : 'Hero Image'}
        css={css`
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          position: absolute;
          top: 0;
          left: 0;
          @media (max-width: ${breaks.small}) {
            object-position: ${position};
          }
        `}
      />
      {title ? (
        <h1
          css={css`
            z-index: 2;
            color: #fff;
            text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
            text-align: center;
            font-size: 3em;
            margin-bottom: 0;
          `}
        >
          {title}
        </h1>
      ) : null}
      {subTitle ? (
        <h2
          css={css`
            z-index: 2;
            color: #fff;
            text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
            text-align: center;
          `}
        >
          {subTitle}
        </h2>
      ) : null}
      {children}
      <button
        onClick={scrolled ? undefined : handleDownClick}
        css={css`
          z-index: 1;
          position: absolute;
          bottom: 10px;
          opacity: ${scrolled ? 0 : 1};
          transition: opacity 400ms ${easeOutExpo};
          background: none;
          border: none;
          outline: none;
          &:hover {
            cursor: pointer;
          }
        `}
      >
        <Down
          css={css`
            animation: ${bounce} 1s ease infinite;
          `}
        />
      </button>
    </div>
  );
};

export default Hero;
